<!-- 订单列表 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
      <div class="header-content">{{title}}</div>
    </header>
    <div class="split-line" />
    <section class="page-info">
      <div class="daily-tabs">
        <van-tabs v-model:active="active" color="#409EFF" title-active-color="#409EFF" @change="onTabChange()">
          <van-tab v-for="item in tabs" :key="item.id" :title="item.text" />
        </van-tabs>
      </div>
      <div v-if="active == 0" class="search-module">
        <van-field
          v-model="sdt"
          placeholder="开始时间"
          readonly
          style="width:100px;height:25px;border:1px;border-radius: 6px;padding:1px 7px;"
          @click="openDatePicker(1)"
        />
        <div class="line-space" />
        <van-field
          v-model="edt"
          placeholder="结束时间"
          readonly
          style="width:100px;height:25px;border:1px;border-radius: 6px;padding:1px 7px;"
          @click="openDatePicker(2)"
        />
        <div class="opt-btn">
          <van-button
            plain
            hairline
            type="primary"
            style="height:25px;"
            @click="fetchData"
          >
            筛选
          </van-button>
        </div>
        <div class="opt-btn">
          <van-button
            plain
            hairline
            type="primary"
            style="height:25px;"
            @click="reset"
          >
            重置
          </van-button>
        </div>
      </div>
      <div class="order-list">
        <van-list
          v-model:loading="loading"
          :finished="list.length==total"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell v-for="(item, index) in list" :key="index" style="background:none;padding: 0px;">
            <div class="order-card" @click="goDetail(item.order_sn)">
              <div class="meal-tag">
                <div v-if="item.shop_type==1">
                  <div v-if="item.meal_mode==1">
                    早餐
                  </div>
                  <div v-else-if="item.meal_mode==2">
                    午餐
                  </div>
                  <div v-else-if="item.meal_mode==3">
                    晚餐
                  </div>
                  <div v-else>
                    其他
                  </div>
                </div>
                <div v-else-if="item.shop_type==3">
                  膳食套餐
                </div>
                <div v-else>
                  预制菜
                </div>
              </div>
              <div class="click-tag">
                点击查看详情
              </div>
              <div class="order-item">
                支付时间: <span>{{getTimeYMDHMS(item.pay_dt)}}</span>
              </div>
              <div class="order-item">
                就餐日期: <span>{{ getTimeYMD(item.order_dt) }}</span>
              </div>
              <div class="order-item">
                订餐时间: <span>{{ getTimeYMDHMS(item.insert_dt) }}</span>
              </div>
              <div class="order-item">
                支付金额: <span class="pay-num">{{item.amount}}</span><span>元</span>
              </div>
              <div class="order-item">
                业务编号: <span>{{item.pay_sn}}</span>
              </div>
              <div class="paid-item">
                订单编号: <span>{{item.order_sn}}</span>
              </div>
              <div class="paid-item-last">
                <div v-if="item.state==1 && item.pay_state==1 && item.refund_state!=2" style="color:#1989fa;width:100%">
                  订单状态：{{ item.delivery_state_str }}
                </div>
                <div v-else-if="item.state!=1" style="width:100%">
                  订单状态：已取消
                </div>
                <div v-else-if="item.refund_state==2" style="color:#FF3333;width:100%">
                  订单状态：已退款（原路退回）
                </div>
                <div v-else-if="item.state==1 && item.pay_state==0 && item.delivery_state==0" style="color:#FF3333;width:100%">
                  订单状态：{{ item.pay_state_str }}
                </div>
                <div v-else  style="color:#FF3333;width:100%">
                  订单状态：{{ item.pay_state_str }}
                </div>
              </div>
            </div>
          </van-cell>
        </van-list>
      </div>
      <!-- 弹窗 -->
      <van-popup v-model:show="showPicker" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          :title="dateTitle"
          :formatter="formatter"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
    </section>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { getStorage, setStorage } from '@/utils/localStorageUtils'
import CommonData from '@/utils/commonMgrData'
import { dateToHMD, getTimeYMD, getTimeYMDHMS } from "@/utils/util"
import { Toast } from 'vant'
import ofsMgr from "@/api/ofsMgr/ofsMgr"

export default {
  name: "orderMgrList",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options)
      if (options && options.type) {
        data.type = options.type
        if (data.type == 1) {
          data.title = '支付订单'
        } else if (data.type == 2) {
          data.title = '退款订单'
        }
        fetchData()
      } else {
        Toast.loading({
          message: '参数异常，请稍后再试',
          forbidClick: true,
          duration: 0
        });
        setTimeout(() =>{
          Toast.clear()
          $router.go(-1)
        },1000)
      }
    });

    const formatter = (type, val) => {
      if (type === 'year') {
        return `${val}年`;
      }
      if (type === 'month') {
        return `${val}月`;
      }
      if (type === 'day') {
        return `${val}日`;
      }
      return val;
    };

    const data = reactive({
      type: 1,
      title: '',
      total: 0,
      list: [],
      loading: false,
      active: 0,
      tabs: [
        {id: 1, text: '全部'},
        {id: 2, text: '今日'},
        {id: 3, text: '昨日'},
        {id: 4, text: '近7日'},
        {id: 5, text: '近1月'}
      ],
      page_index: 1,
      page_size: 10,
      sdt: '',
      edt: '',
      showPicker:false,
      dateType: 1,
      dateTitle: '选择开始时间',
      currentDate: new Date(),
    });

    const openDatePicker = (type) => {
      console.log('openDatePicker')
      data.dateType = type
      if (type == 1) {
        data.dateTitle = '选择开始时间'
      } else {
        data.dateTitle = '选择结束时间'
      }
      data.showPicker = true
    }
    const onConfirm = (value) => {
      console.log(value)
      if (data.dateType == 1) {
        data.sdt = dateToHMD(value) + ' 00:00:00'
      } else {
        data.edt = dateToHMD(value) + ' 23:59:59'
      }
      data.showPicker = false
    }
    const fetchData = () => {
      var post_data = {
        page_index: data.page_index,
        page_size: data.page_size,
        sdt: data.sdt,
        edt: data.edt,
        canteen_id: getStorage(CommonData.KeyStorages.KEY_MGR_CANTEEN_ID)
      }
      if (data.type == 1) {
        post_data.pay_state = 1
      } else {
        post_data.refund_state = 1
      }
      ofsMgr.orderList(post_data).then(res => {
        data.total = res.data.total
        if (data.page_index==1) {
          data.list = res.data.data
        } else {
          data.list = [...data.list, ...res.data.data]
        }
        data.loading = false
      })
    }
    const onLoad = () => {
      console.log("加载更多！")
      data.page_index += 1
      fetchData()
    }

    const reset = () => {
      data.sdt = ''
      data.edt = ''
      data.page_index = 1
      data.page_size = 10
      fetchData()
    }

    const onTabChange = () => {
      console.log(data.active)
      data.page_index = 1
      data.page_size = 10
      data.total = 0
      data.list = []
      switch(data.active) {
        case 0:
          data.sdt = ''
          data.edt = ''
          break
        case 1:
          var nowData = new Date()
          data.sdt = dateToHMD(nowData) + ' 00:00:00'
          data.edt = dateToHMD(nowData) + ' 23:59:59'
          break
        case 2:
          var nowData = new Date()
          var yesterday = nowData.setDate(nowData.getDate()-1)
          data.sdt = getTimeYMD(yesterday) + ' 00:00:00'
          data.edt = getTimeYMD(yesterday) + ' 23:59:59'
          break
        case 3:
          var nowData = new Date()
          data.edt = dateToHMD(nowData) + ' 23:59:59'
          var week = nowData.setDate(nowData.getDate()-7)
          data.sdt = getTimeYMD(week) + ' 00:00:00'
          break
        case 4:
          var nowData = new Date()
          data.edt = dateToHMD(nowData) + ' 23:59:59'
          var month = nowData.setDate(nowData.getDate()-30)
          data.sdt = getTimeYMD(month) + ' 00:00:00'
          break
        default:
          break
      }
      fetchData()
    }

    const goDetail = (order_sn) => {
      const params = {
        order_sn: order_sn
      }
      $router.push({
        name: "orderMgrInfo",
        query: params
      })
    }

    return {
      ...toRefs(data),
      formatter,
      openDatePicker,
      onConfirm,
      fetchData,
      reset,
      getTimeYMDHMS,
      getTimeYMD,
      onLoad,
      onTabChange,
      goDetail
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  background: #eee;
  .page-header {
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;
    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }
    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }
  .split-line {
    width: 100%;
    border-top: thin solid #DCDCDC;
  }
  .page-info {
    width: 100%;
    // background: #DCDCDC;
    .search-module {
      padding: 0 10px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      background: #DCDCDC;
      .line-space {
        margin: 0 5px;
        width: 5px;
        border-top: thin solid #000;
      }
      .opt-btn {
        margin-left: 10px;
      }
    }
    .order-list {
      padding: 0 10px;
      .order-card {
        margin-top: 10px;
        background: #FFF;
        padding: 30px 10px 10px 10px;
        border-radius: 12px;
        position: relative;
        .meal-tag {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 14px;
          padding: 3px 10px;
          color: #fff;
          background: #99BBFF;
          border-radius: 12px 0 12px 0;
        }
        .click-tag {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 14px;
          padding: 3px 10px;
          color: #fff;
          background: #9F88FF;
          border-radius: 0 12px 0 12px;
        }
        .order-item {
          margin-bottom: 5px;
          color: #666;
          font-size: 14px;
          line-height: 20px;
          .pay-num {
            font-size: 20px;
            color: #000;
            line-height: 20px;
            font-weight: bold;
          }
        }
        .order-item-last {
          color: #666;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
